import React from 'react'

function GetRent() {


  var data = [
    { type: "เตรียมดิน", name: "ริปเปอร์", model: "5ขาบราซิล", codenamepage: "", codeid: "RPP001", use: "ไม่ให้เช่า" },
    { type: "เตรียมดิน", name: "ริปเปอร์", model: "5ขาบราซิล", codenamepage: "", codeid: "RPP002", use: "ไม่ให้เช่า" },
    { type: "เตรียมดิน", name: "ริปเปอร์", model: "5ขาตัวก๊อป", codenamepage: "", codeid: "RPP003", use: "ไม่ให้เช่า" },
    { type: "เตรียมดิน", name: "ริปเปอร์", model: "5ขาตัวก๊อป", codenamepage: "", codeid: "RPP004", use: "ไม่ให้เช่า" },
    { type: "เตรียมดิน", name: "ริปเปอร์", model: "5ขาตัวก๊อป", codenamepage: "", codeid: "RPP005", use: "ไม่ให้เช่า" },
    { type: "เตรียมดิน", name: "ริปเปอร์", model: "5ขาตัวก๊อป", codenamepage: "", codeid: "RPP006", use: "ไม่ให้เช่า" },
    { type: "เตรียมดิน", name: "ริปเปอร์", model: "5ขาตัวก๊อป", codenamepage: "", codeid: "RPP007", use: "ไม่ให้เช่า" },
    { type: "เตรียมดิน", name: "ริปเปอร์", model: "5ขานครสวรรค์สติว", codenamepage: "", codeid: "RPP010", use: "ให้เช่า" },
    { type: "เตรียมดิน", name: "ริปเปอร์", model: "5ขานครสวรรค์สติว", codenamepage: "", codeid: "RPP011", use: "ให้เช่า" },
    { type: "เตรียมดิน", name: "ริปเปอร์", model: "5ขานครสวรรค์สติว", codenamepage: "", codeid: "RPP012", use: "ให้เช่า" },
    { type: "เตรียมดิน", name: "ริปเปอร์", model: "5ขานครสวรรค์สติว", codenamepage: "", codeid: "RPP013", use: "ให้เช่า" },
    { type: "เตรียมดิน", name: "ริปเปอร์", model: "5ขานครสวรรค์สติว", codenamepage: "", codeid: "RPP014", use: "ให้เช่า" },
    { type: "เตรียมดิน", name: "ริปเปอร์", model: "5ขานครสวรรค์สติว", codenamepage: "", codeid: "RPP015", use: "ให้เช่า" },
    { type: "เตรียมดิน", name: "ริปเปอร์", model: "5ขานครสวรรค์สติว", codenamepage: "", codeid: "RPP016", use: "ให้เช่า" },
    { type: "เตรียมดิน", name: "ริปเปอร์", model: "5ขานครสวรรค์สติว", codenamepage: "", codeid: "RPP017", use: "ให้เช่า" },
    { type: "เตรียมดิน", name: "ริปเปอร์", model: "5ขานครสวรรค์สติว", codenamepage: "", codeid: "RPP018", use: "ให้เช่า" },
    { type: "เตรียมดิน", name: "ริปเปอร์", model: "5ขานครสวรรค์สติว", codenamepage: "", codeid: "RPP019", use: "ให้เช่า" },
    { type: "เตรียมดิน", name: "ริปเปอร์", model: "ริปเปอร์จรัญญา", codenamepage: "", codeid: "RPP020", use: "ให้เช่า" },
    { type: "เตรียมดิน", name: "ริปเปอร์", model: "ริปเปอร์จรัญญา", codenamepage: "", codeid: "RPP021", use: "ให้เช่า" },
    { type: "เตรียมดิน", name: "ริปเปอร์", model: "ริปเปอร์จรัญญา", codenamepage: "", codeid: "RPP022", use: "ให้เช่า" },
    { type: "เตรียมดิน", name: "ริปเปอร์", model: "ริปเปอร์จรัญญา", codenamepage: "", codeid: "RPP023", use: "ให้เช่า" },
    { type: "เตรียมดิน", name: "ริปเปอร์", model: "ริปเปอร์จรัญญา", codenamepage: "", codeid: "RPP024", use: "ให้เช่า" },
    { type: "เตรียมดิน", name: "ผาน", model: "ผาน 7", codenamepage: "", codeid: "PTT001", use: "ไม่ให้เช่า" },
    { type: "เตรียมดิน", name: "ผาน", model: "ผาน 7", codenamepage: "", codeid: "PTT002", use: "ไม่ให้เช่า" },
    { type: "เตรียมดิน", name: "ผาน", model: "ผาน 7", codenamepage: "", codeid: "PTT003", use: "ไม่ให้เช่า" },

    // { type: "เตรียมดิน", name: "ผาน", model: "ผาน 14", codenamepage: "", codeid: "PTT004", use: "ไม่ให้เช่า" },
    // { type: "เตรียมดิน", name: "ผาน", model: "ผาน 14", codenamepage: "", codeid: "PTT005", use: "ไม่ให้เช่า" },
    // { type: "เตรียมดิน", name: "ผาน", model: "ผาน 14", codenamepage: "", codeid: "PTT006", use: "ไม่ให้เช่า" },
    // { type: "เตรียมดิน", name: "ผาน", model: "ผาน 14", codenamepage: "", codeid: "PTT007", use: "ไม่ให้เช่า" },

    // { type: "เตรียมดิน", name: "ผาน", model: "ผาน 22", codenamepage: "", codeid: "PTT008", use: "ให้เช่า" },
    // { type: "เตรียมดิน", name: "ผาน", model: "ผาน 22", codenamepage: "", codeid: "PTT009", use: "ให้เช่า" },
    // { type: "เตรียมดิน", name: "ผาน", model: "ผาน 22", codenamepage: "", codeid: "PTT010", use: "ให้เช่า" },
    // { type: "เตรียมดิน", name: "ผาน", model: "ผาน 22", codenamepage: "", codeid: "PTT011", use: "ให้เช่า" },

    // { type: "เตรียมดิน", name: "เครื่องปลูก", model: "ไฮสปีด", codenamepage: "", codeid: "PPP001", use: "ให้เช่า" },
    // { type: "เตรียมดิน", name: "เครื่องปลูก", model: " ชุมพล", codenamepage: "", codeid: "PPP002", use: "ให้เช่า" },
    // { type: "บำรุง", name: "คัทอะเวย์", model: "คัทอะเวย์", codenamepage: "", codeid: "CUT001", use: "ให้เช่า" },
    // { type: "บำรุง", name: "คัทอะเวย์", model: "คัทอะเวย์", codenamepage: "", codeid: "CUT002", use: "ให้เช่า" },
    // { type: "บำรุง", name: "คัทอะเวย์", model: "คัทอะเวย์", codenamepage: "", codeid: "CUT003", use: "ให้เช่า" },
    // { type: "บำรุง", name: "คัทอะเวย์", model: "คัทอะเวย์", codenamepage: "", codeid: "CUT004", use: "ให้เช่า" },
    // { type: "บำรุง", name: "คัทอะเวย์", model: "คัทอะเวย์", codenamepage: "", codeid: "CUT005", use: "ให้เช่า" },
    // { type: "บำรุง", name: "คัทอะเวย์", model: " ศรีกำแพงแสน", codenamepage: "", codeid: "CUT006", use: "ให้เช่า" },
    // { type: "บำรุง", name: "คัทอะเวย์", model: " ศรีกำแพงแสน", codenamepage: "", codeid: "CUT007", use: "ให้เช่า" },
    // { type: "บำรุง", name: "คัทอะเวย์", model: " ศรีกำแพงแสน", codenamepage: "", codeid: "CUT008", use: "ให้เช่า" },
    // { type: "บำรุง", name: "คัทอะเวย์", model: "คัทอะเวย์", codenamepage: "", codeid: "CUT009", use: "ให้เช่า" },
    // { type: "บำรุง", name: "คัทอะเวย์", model: "คัทอะเวย์", codenamepage: "", codeid: "CUT010", use: "ให้เช่า" },
    // { type: "บำรุง", name: "คัทอะเวย์", model: "คัทอะเวย์", codenamepage: "", codeid: "CUT011", use: "ให้เช่า" },
    // { type: "บำรุง", name: "คัทอะเวย์", model: "คัทอะเวย์", codenamepage: "", codeid: "CUT012", use: "ให้เช่า" },
    // { type: "บำรุง", name: "คัทอะเวย์", model: "คัทอะเวย์", codenamepage: "", codeid: "CUT013", use: "ให้เช่า" },
    // { type: "บำรุง", name: "คัทอะเวย์", model: "คัทอะเวย์", codenamepage: "", codeid: "CUT014", use: "ให้เช่า" },
    // { type: "บำรุง", name: "คัทอะเวย์", model: "คัทอะเวย์", codenamepage: "", codeid: "CUT015", use: "ให้เช่า" },

    // { type: "บำรุง", name: "เอสอาทีซิก", model: "เอสอาทีซิก", codenamepage: "", codeid: "STR001", use: "ให้เช่า" },
    // { type: "บำรุง", name: "เอ็มพีไอ", model: "เอ็มพีไอ", codenamepage: "", codeid: "MPI001", use: "ให้เช่า" },
    // { type: "บำรุง", name: "ผ่ากอ", model: "ขาริปเปอร์", codenamepage: "", codeid: "CTD001", use: "ให้เช่า" },
    // { type: "บำรุง", name: "ผ่ากอ", model: "ขาริปเปอร์", codenamepage: "", codeid: "CTD002", use: "ให้เช่า" },
    // { type: "บำรุง", name: "ผ่ากอ", model: "จานตัด", codenamepage: "", codeid: "CTD003", use: "ให้เช่า" },
    // { type: "บำรุง", name: "ผ่ากอ", model: "จานตัด", codenamepage: "", codeid: "CTD004", use: "ให้เช่า" },
    // { type: "บำรุง", name: "ผ่ากอ", model: "จานตัด", codenamepage: "", codeid: "CTD005", use: "ให้เช่า" },
    // { type: "บำรุง", name: "ผ่ากอ", model: "จานตัด", codenamepage: "", codeid: "CTD006", use: "ให้เช่า" },
  ];

function showdata(){

  for(const iname of data){ 
    console.log(iname.name)
    
  }
   
}


 

  return (

    <>
   
   <div className="table-responsive">
  
      <table className="table table-auto">
        <thead>

          <tr>
            <th className=''>ริปเปอร์</th>
            {data?.map((d, i) => (
              <th> <div className="circle" onClick={showdata}>{i+1}</div></th>))}
          </tr>


          <tr>
            <th className=''>คัดอะเวย์</th>
            {data?.map((d, i) => (
              <th> <div className="circle" onClick={() => { alert(d.codeid) }}>{i}</div></th>))}
          </tr>
          <tr>
            <th className=''>คัดอะเวย์</th>
            {data?.map((d, i) => (
              <th> <div className="circle" onClick={() => { alert(d.codeid) }}>{i}</div></th>))}
          </tr>
          <tr>
            <th className=''>คัดอะเวย์</th>
            {data?.map((d, i) => (
              <th> <div className="circle" onClick={() => { alert(d.codeid) }}>{i}</div></th>))}
          </tr>
          <tr>
            <th className=''>คัดอะเวย์</th>
            {data?.map((d, i) => (
              <th> <div className="circle" onClick={() => { alert(d.codeid) }}>{i}</div></th>))}
          </tr>



        </thead>

        <tbody>
       




        </tbody>
      </table>
    </div>
    
    </>






  )
}

export default GetRent