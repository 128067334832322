import './AddJob.css'
import { GoogleMap, useJsApiLoader, Polygon, Marker, Circle  } from '@react-google-maps/api';

import React, { useState, useRef, useContext, useCallback, useEffect } from "react";
import * as geolib from 'geolib';

import axios from 'axios'
import Cookies from 'universal-cookie';

function AddJob() {

    const cookies = new Cookies();

   



    function insertMainData() {
   
      
        const today = new Date();
        const yyyy = today.getFullYear();
        let mm = today.getMonth() + 1; // Months start at 0!
        let dd = today.getDate();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;
        const formattedToday = dd + '-' + mm + '-' + yyyy;
        
       
        axios.postForm('https://www.kasetr.com/api/insertMainData.php', 
          {
                isAdd: 'true',
                d1: d1,
                d2: d2,
                d3: d3,
                d4: d4,
                d5: d5,
                d6: JSON.stringify(d6),
                d7: JSON.stringify([d7["latitude"],d7["longitude"]]),
                d8: d8,
                d9: d9,
                d10: d10,
                d11: d11,
                d12: d12,
                d13: d13,
                d14: d14,
                d15: d15,
                d16: d16,
                d17: d17,
                d18: d18,
                d19: d19,
                d20: d20,
                d21: d21,
                d22: d22,
                d23: d23,
                d24: d24,
                d28: formattedToday


           

            
        })
            .then(function (response) {
                if(true == response.data){
                // console.log(response);
                alert("บันทึกเรียบร้อย")
                window.location.href = "/Myjob";
                }else{
                    alert("สงสัยว่าข้อมูลไม่ได้บันทึก กรุณาลองใหม่")
                }
            })

    }


    //พื้นที่เก็บข้อมูล From 
    const [d1, setD1] = useState()
    const [d2, setD2] = useState()
    const [d3, setD3] = useState()
    const [d4, setD4] = useState("")
    const [d5, setD5] = useState(0)
    const [d6, setD6] = useState()
    const [d7, setD7] = useState()
    const [d8, setD8] = useState("หมู่บ้าน")
    const [d9, setD9] = useState("ตำบล")
    const [d10, setD10] = useState("อำเภอ")
    const [d11, setD11] = useState("จังหวัด")
    const [d12, setD12] = useState()
    const [d13, setD13] = useState()
    const [d14, setD14] = useState("")
    const [d15, setD15] = useState()
    const [d16, setD16] = useState("")
    const [d17, setD17] = useState()
    const [d18, setD18] = useState()
    const [d19, setD19] = useState()
    const [d20, setD20] = useState()
    const [d21, setD21] = useState()
    const [d22, setD22] = useState(cookies.get('user')[0]["รหัสเขต"])
    const [d23, setD23] = useState(cookies.get('user')[0]["เขตรับผิดชอบ"])
    const [d24, setD24] = useState(cookies.get('user')[0]["ชื่อพนักงาน"])
    const [d28, setD28] = useState()

    const [choost, setChoost] = useState(false)
    const [choost2, setChoost2] = useState(false)



   

    function getarea(lg) {
        // e.preventDefault();
        // const  area = geolib.getCenter(path);
        // setArea(geolib.getAreaOfPolygon(path)/1600);
        setD7(geolib.getCenter(lg));
        setD5(geolib.getAreaOfPolygon(lg) / 1600);
      
        setD6(lg);
      
  
      




    };

    function d7fun(){
        const centerltg = d7["latitude"]+","+d7["longitude"]
       
        const urlapi = "https://maps.googleapis.com/maps/api/geocode/json?latlng="+centerltg+"&language=th&key=AIzaSyCY3YGZiLsFRWa5JJ2FjoD-LY0Wxnf3Md8"
        // console.log(urlapi)
        axios.get(urlapi)
        
              .then(function (response) {
                // console.log(response.data.results[2]);
                setD8(response.data.results[2].address_components[0].long_name);
                setD9(response.data.results[0].address_components[1].long_name);
                setD10(response.data.results[0].address_components[2].long_name);
                setD11("จังหวัด "+ response.data.results[0].address_components[3].long_name);
        
               
              })
    }



    const { isLoaded } = useJsApiLoader({

        googleMapsApiKey: "AIzaSyCY3YGZiLsFRWa5JJ2FjoD-LY0Wxnf3Md8"
    });




    const [location, setLocation] = useState(null);
    const [center, setCenter] = useState(null);
    const [permissionStatus, setPermissionStatus] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
  


    useEffect(() => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(showPosition);
        } else {
          console.log("Geolocation is not supported by this browser.")
    
        }
    
        function showPosition(position) {
          console.log("Latitude:" + position.coords.latitude + "Longitude:" + position.coords.longitude)
          setCenter({ lat: position.coords.latitude, lng: position.coords.longitude })
          // setPosition({ lat: position.coords.latitude, lng: position.coords.longitude })
    
    
    
        };
    
      }, []);

    useEffect(() => {
        if (!navigator.geolocation) {
          console.error("เบราว์เซอร์ของคุณไม่รองรับการใช้งานตำแหน่ง");
          return;
        }
    
        const watchId = navigator.geolocation.watchPosition(
          (position) => {
            console.log("ตำแหน่งปัจจุบัน:", position.coords);
            console.log("ความแม่นยำของ GPS (เมตร):", position.coords.accuracy);
    
            setLocation({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
              accuracy: position.coords.accuracy, // เก็บค่าความแม่นยำใน state
            });
          },
          (error) => {
            console.error("ไม่สามารถเข้าถึงตำแหน่งได้:", error);
          },
          {
            enableHighAccuracy: true, // ใช้ตำแหน่งที่แม่นยำที่สุดที่อุปกรณ์รองรับ
            maximumAge: 0,
            timeout: 10000,
          }
        );
    
        return () => navigator.geolocation.clearWatch(watchId);
      }, []);
    
    
    
      // ฟังก์ชันเพื่อเริ่มการติดตามตำแหน่งแบบเรียลไทม์
      const startWatchingPosition = () => {
        if (!navigator.geolocation) {
          setErrorMessage("เบราว์เซอร์ของคุณไม่รองรับการใช้งานตำแหน่ง");
          return;
        }
    
        const watchId = navigator.geolocation.watchPosition(
          (position) => {
            setLocation({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
          },
          (error) => {
            console.error("ไม่สามารถเข้าถึงตำแหน่งได้:", error);
            switch (error.code) {
              case error.PERMISSION_DENIED:
                setErrorMessage("การเข้าถึงตำแหน่งถูกปฏิเสธ กรุณาอนุญาตการใช้งานตำแหน่ง");
                break;
              case error.POSITION_UNAVAILABLE:
                setErrorMessage("ไม่สามารถระบุตำแหน่งได้");
                break;
              case error.TIMEOUT:
                setErrorMessage("การระบุตำแหน่งใช้เวลานานเกินไป");
                break;
              default:
                setErrorMessage("เกิดข้อผิดพลาดไม่ทราบสาเหตุ");
            }
          },
          {
            enableHighAccuracy: true,
            maximumAge: 0,
            timeout: 10000,
          }
        );
    
        return watchId;
      };
    
      // เริ่มการติดตามตำแหน่งเมื่อได้รับสิทธิ์
      useEffect(() => {
        let watchId;
        if (permissionStatus === "granted") {
          watchId = startWatchingPosition();
        }
        return () => {
          if (watchId) {
            navigator.geolocation.clearWatch(watchId);
          }
        };
      }, [permissionStatus]);
    
      // ฟังก์ชันเพื่อขอสิทธิ์ใหม่เมื่อผู้ใช้คลิกปุ่ม
      const handleRetry = () => {
        setErrorMessage("");
        setPermissionStatus(null);
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setLocation({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
            setPermissionStatus("granted");
          },
          (error) => {
            console.error("ไม่สามารถเข้าถึงตำแหน่งได้:", error);
            setPermissionStatus(error.code);
            switch (error.code) {
              case error.PERMISSION_DENIED:
                setErrorMessage("การเข้าถึงตำแหน่งถูกปฏิเสธ กรุณาอนุญาตการใช้งานตำแหน่ง");
                break;
              case error.POSITION_UNAVAILABLE:
                setErrorMessage("ไม่สามารถระบุตำแหน่งได้");
                break;
              case error.TIMEOUT:
                setErrorMessage("การระบุตำแหน่งใช้เวลานานเกินไป");
                break;
              default:
                setErrorMessage("เกิดข้อผิดพลาดไม่ทราบสาเหตุ");
            }
          },
          {
            enableHighAccuracy: true,
            maximumAge: 0,
            timeout: 10000,
          }
        );
      };

    useEffect(() => {
        getLocation1(); // จะทำงานครั้งแรก
  

    }, []);


    function getLocation1() {

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(showPosition);
        } else {
            console.log("Geolocation is not supported by this browser.")

        }
    };
    function getLocation(e) {
        e.preventDefault();
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(showPosition);
        } else {
            console.log("Geolocation is not supported by this browser.")

        }
    };

    function showPosition(position) {
        // console.log("Latitude:" + position.coords.latitude + "Longitude:" + position.coords.longitude)
        setCenter({ lat: position.coords.latitude, lng: position.coords.longitude })
        // setPosition({ lat: position.coords.latitude, lng: position.coords.longitude })



    };





    
    const onMapClick = (e) => {

        setPath([...path, { lat: e.latLng.lat(), lng: e.latLng.lng() }])
        getarea([...path, { lat: e.latLng.lat(), lng: e.latLng.lng() }])

    };

    const onClickDelete = (e) => {
        e.preventDefault();
        setPath(path.slice(0, -1));
        // console.log(typeof (path[0]));
        setD5(geolib.getAreaOfPolygon(path) / 1600);
    }
    const [path, setPath] = useState([]);

    // Define refs for Polygon instance and listeners
    const polygonRef = useRef(null);
    const listenersRef = useRef([]);

    // Call setPath with new edited path
    const onEdit = useCallback(() => {
        if (polygonRef.current) {
            const nextPath = polygonRef.current
                .getPath()
                .getArray()
                .map(latLng => {
                    return { lat: latLng.lat(), lng: latLng.lng() };
                });
            getarea(nextPath);
            setPath(nextPath);
        }
    }, [setPath]);

    // Bind refs to current Polygon and listeners
    const onLoad = useCallback(
        polygon => {
            polygonRef.current = polygon;
            const path = polygon.getPath();
            listenersRef.current.push(
                path.addListener("set_at", onEdit),
                path.addListener("insert_at", onEdit),
                path.addListener("remove_at", onEdit)
            );
        },
        [onEdit]
    );

    // Clean up refs
    const onUnmount = useCallback(() => {
        listenersRef.current.forEach(lis => lis.remove());
        polygonRef.current = null;
    }, []);

    if (undefined == cookies.get("user")) {
        window.location.href = "/login";
    
    
    } else {
    
      if ( "นักส่งเสริม" == cookies.get('user')[0]["ตำแหน่ง"]||"หัวหน้าเขต" == cookies.get('user')[0]["ตำแหน่ง"]) {
      
        return (
                <div className='container-fluid'>
                    <div class="d-flex justify-content-center">
                        <div className='card'>
                            <form >

                                <div class="mb-3">
                                    <label for="email" class="form-label">โควต้า:</label>
                                    <input type="text" class="form-control" id="d1" placeholder="โควต้า" onChange={(e) => setD1(e.target.value)} />
                                </div>
                                <div class="mb-3 ">
                                    <label for="email" class="form-label">ชื่อชาวไร่:</label>
                                    <input type="text" class="form-control" id="d2" placeholder="ชื่อชาวไร่" onChange={(e) => setD2(e.target.value)} />
                                </div>


                                <div class="mb-3">
                                    <label for="text" class="form-label">เบอร์โทร:</label>

                                    <input type="tel" class="form-control" id="d3" onChange={(e) => setD3(e.target.value)} name="phone" placeholder="เบอร์โทร" />
                                </div>


                                <div class="mb-3">
                                    <label class="form-label">การจัดหาพันธุ์อ้อย:</label>
                                    <select class="form-select" id="d12" onChange={(e) => setD12(e.target.value)} >
                                        <option value="ไม่ได้เลือก" selected>เลือก...</option>
                                        <option value="พันธุ์อ้อยจากโรงงาน">พันธุ์อ้อยจากโรงงาน</option>
                                        <option value="พันธุ์อ้อยจากชาวไร่ที่ขาย">พันธุ์อ้อยจากชาวไร่ที่ขาย</option>
                                        <option value="พันธุ์อ้อยของตนเอง">พันธุ์อ้อยของตนเอง</option>
                                    </select>
                                </div>


                                <div class="mb-3" id="d13">
                                    <label for="email" class="form-label">ปุ๋ย-สารเคมี:</label>
                                    <select class="form-select" onChange={(e) => setD13(e.target.value)} >
                                        <option value="ไม่ได้เลือก" selected>เลือก...</option>
                                        <option value="โรงงานจัดเตรียม">โรงงานจัดเตรียม</option>
                                        <option value="ชาวไร่เตรียมเอง">ชาวไร่เตรียมเอง</option>
                                    </select>
                                </div>


                                <div class="mb-3" id="d14" >
                                    <label class="form-label" >ชุดเตรียมดิน:</label>

                                    <select class="form-select" onChange={(e) => { if (2 == e.target.value) { setChoost(!false); setD14("กรุณาพิม"); setD15("โควต้าผู้รับเหมา") } else { setChoost(false); setD15(e.target.value) } }} >
                                        <option selected  >เลือก...</option>
                                        <option value="โรงงาน">โรงงาน</option>
                                        <option value="2" >ผู้รับเหมา</option>
                                        <option value="ชาวไร่เตรียมเอง">ชาวไร่เตรียมเอง</option>

                                    </select>
                                    {choost ? (<div class="mb-3"><label class="form-label">ชุดผู้รับเหมาเตรียมดิน:</label>
                                        <input type="search" class="form-control" id="d14d15" value={d14 + "," + d15} placeholder="ชุดผู้รับเหมาเตรียมดิน" name="" data-bs-toggle="modal" data-bs-target="#myModal22" />
                                    </div>) : (<></>)}


                                    <div className="modal" id="myModal22">
                                        <div className="modal-dialog modal-dialog-centered">
                                            <div className="modal-content">


                                                <div className="modal-header">
                                                    <h4 className="modal-title">ชุดผู้รับเหมาเตรียมดิน</h4>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                                                </div>


                                                <div className="modal-body">
                                                    <div class="mb-3">
                                                        <label class="form-label">โควต้ารับเหมา:</label>
                                                        <input type="text" class="form-control" id="d14" onChange={(e) => setD14(e.target.value)} placeholder="โควต้ารับเหมา" name="" />
                                                    </div>

                                                    <div class="mb-3">
                                                        <label class="form-label">ชื่อ-สกุล (ไม่ต้องใส่คำนำหน้าชื่อ)โควต้าผู้รับเหมา:</label>
                                                        <input type="text" class="form-control" id="d15" onChange={(e) => setD15(e.target.value)} placeholder="ชื่อโควต้าผู้รับเหมา" name="" />
                                                    </div>

                                                </div>


                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-success" data-bs-dismiss="modal">ตกลง</button>
                                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal">ปิด</button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>





                                <div class="mb-3" id="d17">
                                    <label for="text" class="form-label" >ชุดปลูก:</label>
                                    <select class="form-select" onChange={(e) => { if (2 == e.target.value) { setChoost2(!false); setD16("กรุณาพิม"); setD17("โควต้าผู้รับเหมา") } else { setChoost2(false); setD17(e.target.value) } }}  >
                                        <option selected  >เลือก...</option>
                                        <option value="ชาวไร่ปลูกเอง">ชาวไร่ปลูกเอง</option>
                                        <option value="2" >ผู้รับเหมา</option>


                                    </select>
                                    {choost2 ? (<div class="mb-3"><label class="form-label">ชุดผู้รับเหมาปลูก:</label>
                                        <input type="search" class="form-control" id="d16d17" value={d16 + "," + d17} placeholder="ชุดผู้รับเหมาปลูก" name="" data-bs-toggle="modal" data-bs-target="#myModal3" />
                                    </div>) : (<></>)}


                                    <div className="modal" id="myModal3">
                                        <div className="modal-dialog modal-dialog-centered">
                                            <div className="modal-content">


                                                <div className="modal-header">
                                                    <h4 className="modal-title">ชุดผู้รับเหมาปลูก</h4>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                                                </div>


                                                <div className="modal-body">
                                                    <div class="mb-3">
                                                        <label for="email" class="form-label">โควต้ารับเหมา:</label>
                                                        <input type="text" class="form-control" id="d16" onChange={(e) => setD16(e.target.value)} placeholder="โควต้ารับเหมา" name="" />
                                                    </div>

                                                    <div class="mb-3">
                                                        <label for="email" class="form-label">ชื่อ-สกุล (ไม่ต้องใส่คำนำหน้าชื่อ)โควต้าผู้รับเหมา:</label>
                                                        <input type="text" class="form-control" id="d17" onChange={(e) => setD17(e.target.value)} placeholder="ชื่อโควต้าผู้รับเหมา" name="" />
                                                    </div>

                                                </div>


                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-success" data-bs-dismiss="modal">ตกลง</button>
                                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal">ปิด</button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>










                                <div class="mb-3">
                                    <label for="email" class="form-label">พืชปัจจุบัน:</label>
                                    <input type="text" class="form-control" id="d18" placeholder="พืชปัจจุบัน" onChange={(e) => setD18(e.target.value)} />
                                </div>

                                <div class="mb-3 ">
                                    <label for="email" class="form-label">วันเก็บเกี่ยว:</label>
                                    <input type="date" class="form-control" id="d19" placeholder="วันเก็บเกี่ยว" onChange={(e) => setD19(e.target.value)} />
                                </div>
                                <div class="mb-3 ">
                                    <label for="email" class="form-label">วันพร้อมปลูก:</label>
                                    <input type="date" class="form-control" id="d20" placeholder="วันพร้อมปลูก" onChange={(e) => setD20(e.target.value)} />
                                </div>







                                <div class="mb-3 ">
                                    <label for="email" class="form-label">จำนวนพื้นที่:</label>
                                    <input type="text" class="form-control" id="d5" placeholder="จำนวนพื้นที่" name="" data-bs-toggle="modal" data-bs-target="#myModalarea" value={d5.toFixed(2)} />

                                    <div className="modal" id="myModalarea">
                                        <div className="modal-dialog modal-fullscreen">
                                            <div className="modal-content">


                                                <div className="modal-header">
                                                    <h4 className="modal-title">วาดแปลง</h4>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" ></button>
                                                </div>


                                                <div className="modal-body">
                                                    {isLoaded ? (
                                                        <>
                                                            <div className='setpage'>

                                                                {/* <NavBar />
            <p>{center.lat},{center.lng}</p>
            <p><a href={"http://map.google.com/?q=" + center.lat + "," + center.lng}>{center.lat},{center.lng}</a></p> */}
                                                                

                                                                <GoogleMap
                                                                 
                                                                    // mapContainerStyle={containerStyle}
                                                                    mapContainerClassName="map-container"
                                                                    zoom={18}
                                                                    center={center}
                                                                    mapTypeId='satellite'
                                                                    onClick={onMapClick}

                                                                >

                                                                    {/* <Marker position={position}

                        icon={"http://maps.google.com/mapfiles/ms/icons/green-dot.png"} /> */}

                                                                    <Polygon
                                                                        // Make the Polygon editable / draggable
                                                                        editable
                                                                        options={{ fillColor: '#d9150b', fillOpacity: 0.2, strokeColor: "#d9150b", strokeOpacity: 0.8 }}
                               
                                                                        // draggable
                                                                        path={path}
                                                                        // Event used when manipulating and adding points
                                                                        onMouseUp={onEdit}
                                                                        // Event used when dragging the whole Polygon
                                                                        onDragEnd={onEdit}
                                                                        onLoad={onLoad}
                                                                        onUnmount={onUnmount}
                                                                    />


<Marker
            position={location}
            icon={{
              path: window.google.maps.SymbolPath.CIRCLE, // ใช้ window.google แทน google
              scale: 6,
              fillColor: "#4285F4", // สีฟ้าเหมือนจุดสีน้ำเงินใน Google Maps
              fillOpacity: 1,
              strokeWeight: 2,
              strokeColor: "#ffffff",
            }}
          />
          {location && (
            <Circle
              center={{ lat: location.lat, lng: location.lng }}
              radius={location.accuracy} // ใช้ค่าความแม่นยำกำหนดรัศมี
              options={{
                fillColor: "#4285F4",
                fillOpacity: 0.2,
                strokeColor: "#4285F4",
                strokeOpacity: 0.3,
                strokeWeight: 1,
              }}
            />
          )}
                                                                </GoogleMap>
                                                                {/* <IoAddCircleOutline className='nameicon' /> */}
                                                                
                                                                {/* <button variant="primary" className='btnadd' onClick={getarea}>Area </button> */}
                                                               
                                                            </div>
                                                        </>
                                                    ) : <></>}
                                                </div>
                                                <div className='d-inline-flex'>
                                                <button variant="primary" className='w-50 btn btn-info' onClick={getLocation}>จุดที่ตั้งของฉัน</button><button variant="primary" className='w-50 btn btn-warning' onClick={onClickDelete}>ลบจุดออก </button>
                                                </div>
                                                <div className="modal-footer"><p></p>
                                               <p>-ขนาดของพื้นที่ : {d5.toFixed(2)}ไร่</p>
                                                    <button type="button" className="w-100 btn btn-lg btn-success" data-bs-dismiss="modal" onClick={d7fun}>ตกลง</button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>




                                </div>








                                <div class="mb-3 ">
                                    <label for="text" class="form-label">ชื่อหมู่บ้าน:</label>
                                    <input type="text" class="form-control" id="d8d9d10d11" value={d8} onChange={(e) => setD8(e.target.value)} placeholder="ที่ตั้งอยู่แปลง" name="" />
                                </div>
                                <div class="mb-3 ">
                                    <label for="text" class="form-label">ที่ตั้งอยู่แปลง:</label>
                                    <input type="text" class="form-control" id="d8d9d10d11" value={d9 + "," + d10 + "," + d11} placeholder="ที่ตั้งอยู่แปลง" name="" />
                                </div>
                                <div class="mb-3 ">
                                    <label for="email" class="form-label">ประเภทพื้นที่:</label>
                                    <select class="form-select" onChange={(e) => setD21(e.target.value)}>
                                        <option selected>เลือก...</option>
                                        <option value="ตนเอง">ตนเอง</option>
                                        <option value="เช่า">เช่า</option>
                                        <option value="ลูกไร่">ลูกไร่</option>
                                    </select>
                                </div>

                                <button type="button" class="w-100 btn btn-primary" onClick={insertMainData}>บันทึกข้อมูล</button>
                            </form>
                        </div>
                    </div>
                </div>
            )

        } 
    }
    


}

export default AddJob