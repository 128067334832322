import React, { useState, useEffect } from "react";
import { GoogleMap, useJsApiLoader, Marker, Circle } from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "450px",
};

function MapContainer() {
  const [location, setLocation] = useState(null);
  const [center, setCenter] = useState(null);
  const [permissionStatus, setPermissionStatus] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyCY3YGZiLsFRWa5JJ2FjoD-LY0Wxnf3Md8", // ใส่ API Key ของคุณ
  });


  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      console.log("Geolocation is not supported by this browser.")

    }

    function showPosition(position) {
      console.log("Latitude:" + position.coords.latitude + "Longitude:" + position.coords.longitude)
      setCenter({ lat: position.coords.latitude, lng: position.coords.longitude })
      // setPosition({ lat: position.coords.latitude, lng: position.coords.longitude })
      console.log("test......................");
      console.log({ lat: position.coords.latitude, lng: position.coords.longitude })



    };

  }, []);
  useEffect(() => {
    if (!navigator.geolocation) {
      console.error("เบราว์เซอร์ของคุณไม่รองรับการใช้งานตำแหน่ง");
      return;
    }

    const watchId = navigator.geolocation.watchPosition(
      (position) => {
        console.log("ตำแหน่งปัจจุบัน:", position.coords);
        console.log("ความแม่นยำของ GPS (เมตร):", position.coords.accuracy);

        setLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
          accuracy: position.coords.accuracy, // เก็บค่าความแม่นยำใน state
        });
      },
      (error) => {
        console.error("ไม่สามารถเข้าถึงตำแหน่งได้:", error);
      },
      {
        enableHighAccuracy: true, // ใช้ตำแหน่งที่แม่นยำที่สุดที่อุปกรณ์รองรับ
        maximumAge: 0,
        timeout: 10000,
      }
    );

    return () => navigator.geolocation.clearWatch(watchId);
  }, []);



  // ฟังก์ชันเพื่อเริ่มการติดตามตำแหน่งแบบเรียลไทม์
  const startWatchingPosition = () => {
    if (!navigator.geolocation) {
      setErrorMessage("เบราว์เซอร์ของคุณไม่รองรับการใช้งานตำแหน่ง");
      return;
    }

    const watchId = navigator.geolocation.watchPosition(
      (position) => {
        setLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      },
      (error) => {
        console.error("ไม่สามารถเข้าถึงตำแหน่งได้:", error);
        switch (error.code) {
          case error.PERMISSION_DENIED:
            setErrorMessage("การเข้าถึงตำแหน่งถูกปฏิเสธ กรุณาอนุญาตการใช้งานตำแหน่ง");
            break;
          case error.POSITION_UNAVAILABLE:
            setErrorMessage("ไม่สามารถระบุตำแหน่งได้");
            break;
          case error.TIMEOUT:
            setErrorMessage("การระบุตำแหน่งใช้เวลานานเกินไป");
            break;
          default:
            setErrorMessage("เกิดข้อผิดพลาดไม่ทราบสาเหตุ");
        }
      },
      {
        enableHighAccuracy: true,
        maximumAge: 0,
        timeout: 10000,
      }
    );

    return watchId;
  };

  // เริ่มการติดตามตำแหน่งเมื่อได้รับสิทธิ์
  useEffect(() => {
    let watchId;
    if (permissionStatus === "granted") {
      watchId = startWatchingPosition();
    }
    return () => {
      if (watchId) {
        navigator.geolocation.clearWatch(watchId);
      }
    };
  }, [permissionStatus]);

  // ฟังก์ชันเพื่อขอสิทธิ์ใหม่เมื่อผู้ใช้คลิกปุ่ม
  const handleRetry = () => {
    setErrorMessage("");
    setPermissionStatus(null);
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        setPermissionStatus("granted");
      },
      (error) => {
        console.error("ไม่สามารถเข้าถึงตำแหน่งได้:", error);
        setPermissionStatus(error.code);
        switch (error.code) {
          case error.PERMISSION_DENIED:
            setErrorMessage("การเข้าถึงตำแหน่งถูกปฏิเสธ กรุณาอนุญาตการใช้งานตำแหน่ง");
            break;
          case error.POSITION_UNAVAILABLE:
            setErrorMessage("ไม่สามารถระบุตำแหน่งได้");
            break;
          case error.TIMEOUT:
            setErrorMessage("การระบุตำแหน่งใช้เวลานานเกินไป");
            break;
          default:
            setErrorMessage("เกิดข้อผิดพลาดไม่ทราบสาเหตุ");
        }
      },
      {
        enableHighAccuracy: true,
        maximumAge: 0,
        timeout: 10000,
      }
    );
  };

  if (!isLoaded) return <div>Loading map...</div>;

  return (
    <div>
      <h1>แสดงตำแหน่งปัจจุบันของคุณบน Google Maps</h1>
      {errorMessage && (
        <div style={{ color: "red", marginBottom: "10px" }}>
          {errorMessage}
          <br />
          <button onClick={handleRetry} style={{ marginTop: "10px" }}>
            ขอสิทธิ์เข้าถึงตำแหน่งใหม่
          </button>
        </div>
      )}
      {!location && !errorMessage && <div>กำลังค้นหาตำแหน่งของคุณ...</div>}
      {location && (
        <GoogleMap
          mapContainerStyle={containerStyle}
          mapTypeId='satellite'

          center={center}
          zoom={15}
          options={{
            streetViewControl: false, // ปิดตัวเลือก Street View


          }}
        >
          {/* Marker แสดงตำแหน่งปัจจุบัน */}
          <Marker
            position={location}
            icon={{
              path: window.google.maps.SymbolPath.CIRCLE, // ใช้ window.google แทน google
              scale: 6,
              fillColor: "#4285F4", // สีฟ้าเหมือนจุดสีน้ำเงินใน Google Maps
              fillOpacity: 1,
              strokeWeight: 2,
              strokeColor: "#ffffff",
            }}
          />
          {location && (
            <Circle
              center={{ lat: location.lat, lng: location.lng }}
              radius={location.accuracy} // ใช้ค่าความแม่นยำกำหนดรัศมี
              options={{
                fillColor: "#4285F4",
                fillOpacity: 0.2,
                strokeColor: "#4285F4",
                strokeOpacity: 0.3,
                strokeWeight: 1,
              }}
            />
          )}

        </GoogleMap>
      )}
    </div>
  );
}

export default MapContainer;
