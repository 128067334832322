import React, { useState, useEffect } from "react";
import { GoogleMap, useJsApiLoader, Polygon, Marker, Circle  } from '@react-google-maps/api';
import axios from 'axios'
import Cookies from 'universal-cookie';
import './MyJob.css'
function MyJob() {
  
    useEffect(() => {
        getData();




    }, []);


    
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: "AIzaSyCY3YGZiLsFRWa5JJ2FjoD-LY0Wxnf3Md8", // ใส่ API Key ของคุณ
      });
    
      const containerStyle = {
        width: "100%",
        height: "450px",
      };
    const cookies = new Cookies();
    
    const [mydata1, setMydata1] = useState();
    const [todayareasum, setTodayareasum] = useState();
    const [todaypang, setTodaypang] = useState();

    // console.log(typeof(cookies.get('user')[0]["เขตรับผิดชอบ"]))
    // console.log(cookies.get('user')[0]["เขตรับผิดชอบ"])
    // console.log(cookies)
   
    const todatarr = []
    function getData() {
        axios.get('https://api.kasetr.com/getMainData.php').then(res => {
            var data = res.data

            // console.log(data)
          
            let todayarea = 0;
            let todaycon = 0;
         

            for (var ii in data) {



              if( data[ii]["รหัสนสร"] == cookies.get('user')[0]["เขตรับผิดชอบ"]){
            //   console.log(data)
                
                    todatarr.push(data[ii])
                  
                    todayarea = todayarea + parseFloat(data[ii]["พื้นที่แจ้งจด"])
                    todaycon = todaycon + 1
              }
                

            }
            setTodaypang(todaycon);
            setTodayareasum(todayarea.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }))
            
            setMydata1(todatarr)
            // console.log(todatarr[0].id)
            console.log(todatarr)


        })
    }







    return (



        <div className='container-fluid'>
         
            
            <a type="button" class="w-100 btn btn-primary" href="Menu" >ย้อนกลับ </a>
<p></p>
               
<p><h5>จำนวนแปลงที่แจ้ง {todaypang} แปลง พื้นที่รวม {todayareasum} ไร่</h5></p>
<div className="table-responsive">
                <table className="table table-hover  w-auto mw-100">
                    <thead>
                        <tr>
                            <th scope="col">id</th>
                            <th scope="col">วันที่แจ้ง</th>
                           
                            <th scope="col">โควต้า</th>
                            <th scope="col" className="col-4">ชื่อชาวไร่</th>
                            <th scope="col">พื้นที่จดแจ้ง</th>
                       
                            <th scope="col">วันพร้อมปลูก</th>
                            <th scope="col">รูปแปลง</th>
                        </tr>
                    </thead>
                    <tbody>


                        {mydata1?.map((d, i) => (

                            <tr>
                                < td >{i + 1}</td>
                                < td >{d["tm1"]}</td>

                           
                                < td >{d["รหัสโควต้า"]}</td>
                                < td >{d["ชื่อชาวไร่"]}</td>
                                < td >{parseFloat(d["พื้นที่แจ้งจด"]).toFixed(2)}</td>
                               
                                < td >{d["วันพร้อมปลูก"]}</td>
                                < td ><a  data-bs-toggle="modal" data-bs-target={"#itemmap"+d["id"].toString()} >แผนที่</a> 
                                
                                <div className="modal" id={"itemmap"+d["id"].toString()}>
                                        <div className="modal-dialog modal-fullscreen">
                                            <div className="modal-content">


                                                <div className="modal-header">
                                                    <h4 className="modal-title">ข้อมูลแปลง</h4>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" ></button>
                                                </div>


                                                <div className="modal-body">
                                                    {isLoaded ? (
                                                        <>
                                                            <div className='setpage'>

                                                   
                                                                <GoogleMap
                                                                 
                                                                    mapContainerStyle={containerStyle}
                                                                    // mapContainerClassName="map-container"
                                                                    zoom={18}
                                                                    // center={{"lat":15.637695517979921,"lng":99.67616008699918}}
                                                                    center={{"lat":JSON.parse(d["GPSCenter"])[0],"lng":JSON.parse(d["GPSCenter"])[1]}}
                                                                    mapTypeId='satellite'
                                                                   

                                                                >


                                                                    <Polygon
                                                                    
                                                                        options={{ fillColor: '#d9150b', fillOpacity: 0.2, strokeColor: "#d9150b", strokeOpacity: 0.8 }}
                               
                                                                       
                                                                        path={JSON.parse(d["Polygon"])}
                                                                   
                                                                       
                                                                    
                                                                    />



                                                                </GoogleMap>

                                                           
                                                            </div>
                                                        </>
                                                    ) : <></>}
                                                </div>

                                                
                                                <div className="modal-body">
                                                    <div class="mb-3">
                                                        <label class="form-label">รหัส : {d["รหัสโควต้า"]}</label> &nbsp;&nbsp;
                                                        <label class="form-label">ชื่อชาวไร่ : {d["ชื่อชาวไร่"]}</label> &nbsp;&nbsp;
                                                       <label class="form-label">พื้นที่ : {parseFloat(d["พื้นที่แจ้งจด"]).toFixed(2)} ไร่</label>  &nbsp;&nbsp;
                                                        <label class="form-label">วันพร้อมปลูก : {d["วันพร้อมปลูก"]}</label> &nbsp;&nbsp;
                                                        <label class="form-label">เบอร์โทรชาวไร่ : {d["เบอร์โทร"]}</label> &nbsp;&nbsp;
                                                        <label class="form-label">ที่ตั้งแปลง : {d["หมู่บ้าน"] },{d["ตำบล"] },{d["อำเภอ"] }</label> &nbsp;&nbsp;
                                                    </div>

                                                  

                                                </div>


                                                <div className="modal-footer">
                                               
                                                    <button type="button" className="w-100 btn  btn-danger" data-bs-dismiss="modal">ปิด</button>
                                                </div>
                                               

                                            </div>
                                        </div>
                                   
                                   
                                    </div></td>
                               
                            </tr>

                        ))}






                    </tbody>
                </table>

                </div>
        </div>
  )
}

export default MyJob