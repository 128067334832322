import './App.css';

import { GoogleMap, useJsApiLoader, Polygon } from '@react-google-maps/api';
import React, { useState, useRef, useCallback,createContext,useContext} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

import HomePage from './Components/HomePage/HomePage';
import Login from './Components/Login/Login';
import Tools from './Components/Tools/Tools';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import NotFoud from './Components/NotFoud/NotFoud';
import PolyGon from './Components/PolyGons/PolyGon';
import Camera from './Components/Camera/Camera';


import DashBoard from './Components/DashBoard/DashBoard';

import AddJob from './Components/AddJob/AddJob';
import Menu from './Components/Menu/Menu';
import Maps from './Components/Maps/Maps';
import ReportData from './Components/ReportData/ReportData';
import AddUser from './Components/AddUser/AddUser';
import User from './Components/User/User';
import LogOut from './Components/LogOut/LogOut';
import MyJob from './Components/MyJob/MyJob';
import Geo from './Components/Geo/Geo';
import ReServe from './Rent/ReServe/ReServe';
import HomeRent from './Rent/HomeRent/HomeRent';
import MapContainer from './Components/MapContainer/MapContainer';

export const UserContext = createContext()




function App() {
  



return(

 <BrowserRouter>


  <Routes>
    <Route path='*' element={<NotFoud />} />
    <Route path="/" element={<DashBoard />} />
    <Route index element={<DashBoard />} />

    <Route path="/home" element={<HomePage />} />
    <Route path="/polygon" element={<PolyGon />} />
    <Route path="/camera" element={<Camera />} />
    <Route path="/login" element={<Login />} />
    <Route path="/logout" element={<LogOut />} />
    <Route path='/dashboard' element={<DashBoard/>}/>
    <Route path="/tools" element={<Tools />} />
    <Route path="/AddJob" element={<AddJob />} />
    <Route path="/Myjob" element={<MyJob />} />
    <Route path='/Menu' element={<Menu/>}></Route>
    <Route path='/Maps' element={<Maps/>}></Route>
    <Route path='/Report' element={<ReportData/>}></Route>
    <Route path='/adduser' element={<AddUser/>}></Route>
    <Route path='/user' element={<User/>}></Route>
    <Route path='/Geo' element={<Geo/>}></Route>
    <Route path='/ReServe' element={<ReServe/>}></Route>
    <Route path='/HomeRent' element={<HomeRent/>}></Route>
    <Route path='/MapContainer' element={<MapContainer/>}></Route>

    

  </Routes>
</BrowserRouter>

);

 
}
export default App;
