import React from 'react'

function ReServe() {
  return (
    <div>

            

                                <div class="mb-3">
                                    <label for="email" class="form-label">โควต้า:</label>
                                    <input type="text" class="form-control" id="d1" placeholder="โควต้า" />
                                </div>
                                <div class="mb-3 ">
                                    <label for="email" class="form-label">ชื่อชาวไร่:</label>
                                    <input type="text" class="form-control" id="d2" placeholder="ชื่อชาวไร่"/>
                                </div>


                                <div class="mb-3">
                                    <label for="text" class="form-label">เบอร์โทร:</label>

                                    <input type="tel" class="form-control" id="d3" name="phone" placeholder="เบอร์โทร" />
                                </div>
                                <div class="mb-3 ">
                                    <label for="email" class="form-label">จำนวนพื้นที่ :</label>
                                    <input type="text" class="form-control" id="d2" placeholder="จำนวนพื้นที่"/>
                                </div>
                           
                                <div class="mb-3 ">
                                    <label for="email" class="form-label">จำนวนวันที่จะใช้งาน:</label>
                                    <input type="text" class="form-control" id="d2" placeholder="จำนวนวัน"/>
                                </div>

                                <div class="mb-3">
                                    <label for="text" class="form-label">วันที่จะใช้เครื่องมือ:</label>

                                    <input type="date" class="form-control" id="d3" name="date" placeholder="date" />
                                </div>


                                <div class="mb-3">
                                    <label class="form-label">การใช้งาน:</label>
                                    <select class="form-select" id="d12" onChange={(e) => console.log(e.target.value)} >
                                        <option value="ไม่ได้เลือก" selected>เลือก...</option>
                                        <option value="นำไปใช้งานเอง">นำไปใช้งานเอง</option>
                                        <option value="นำไปรับจ้าง">นำไปรับจ้าง</option>
                                       
                                    </select>
                                </div>
                                <div class="mb-3">
                                    <label class="form-label">ชาวไร่ของ นสร.:</label>
                                    <select class="form-select" id="d12" onChange={(e) => console.log(e.target.value)} >
                                        <option value="ไม่ได้เลือก" selected>เลือก...</option>
                                        <option value="นำไปใช้งานเอง">นำไปใช้งานเอง</option>
                                        <option value="นำไปรับจ้าง">นำไปรับจ้าง</option>
                                       
                                    </select>
                                </div>






</div>
  )
}

export default ReServe