import React from 'react';
import GetRent from '../GetRent/GetRent';
import RentNow from '../RentNow/RentNow';
import Additem from '../Additem/Additem';
import './HomeRent.css';
import AddNameitem from '../AddNameitem/AddNameitem';
function HomeRent() {

  return (
    <>
    

      <div class="container mt-3 ">
        <h2>ตรางแสดงสถานะ : เครื่องมือ</h2>
        <p>
          <button type="button" class="btn btn-primary">เครื่องมือเตรียมดิน</button>
          <button type="button" className="btn btn-primary">เครื่องมืองานบำรุง</button>
        </p>
         <GetRent /> 
        {/* <AddNameitem /><p></p> */}
        {/* <Additem />
        <RentNow /> */}
      </div>


    </>
  )
}

export default HomeRent