import React, { useRef, useState, useEffect } from 'react';

function Camera() {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [imageSrc, setImageSrc] = useState(null);

  // เปิดกล้องเมื่อ component ถูก mount
  useEffect(() => {
    const startCamera = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
        }
      } catch (error) {
        console.error('Error accessing camera:', error);
      }
    };
    startCamera();
  }, []);

  // ฟังก์ชันถ่ายรูป
  const takePicture = () => {
    const context = canvasRef.current.getContext('2d');
    context.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);
    const imageData = canvasRef.current.toDataURL('image/png');
    setImageSrc(imageData); // เก็บรูปภาพที่ถ่ายได้ใน state
  };

  return (
    <div>
      <h1>Camera Capture</h1>
      <video ref={videoRef} autoPlay style={{ width: '100%', height: 'auto' }} />
      <button onClick={takePicture}>Capture</button>
      
      <canvas ref={canvasRef} style={{ display: 'none' }} width="640" height="480" />
      
      {imageSrc && (
        <div>
          <h2>Captured Image:</h2>
          <img src={imageSrc} alt="captured" width="100%" height="auto" />
        </div>
      )}
    </div>
  )
}

export default Camera