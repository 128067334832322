import React, { PureComponent } from 'react';
import { BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

function Tools() {

  const data = [
    {
      name: 'นาย A',
      "67/68": 589,
      "68/69": 666,
      "69/70": 759,
    },
    {
      name: 'นาย B',
      "67/68": 354,
      "68/69": 845,
      "69/70": 687,
    },
    {
      name: 'นาย C',
      "67/68": 976,
      "68/69": 335,
      "69/70": 235,
    },
  ];


function datee(d){
        const today = d;
        // const today = new Date();
        const yyyy = today.getFullYear();
        let mm = today.getMonth() + 1; // Months start at 0!
        let dd = today.getDate();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;
        const formattedToday = dd + '/' + mm + '/' + yyyy;
        console.log(formattedToday)
}
function addDays(date, days) {
  const newDate = new Date(date.getTime() + days * 24 * 60 * 60 * 1000);
  return newDate;
}
  // const [value, onChange] = useState(new Date());
  const minDate = new Date();
  const maxDate  = addDays(minDate,45)


  return (
    <>
      <div>
        <Calendar locale={'th-TH'} minDate={minDate} maxDate={maxDate} onChange={(event)=>{datee(event)}} />
      </div>

   

    </>
  )
}

export default Tools